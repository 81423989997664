// variables
@import '../helpers/variables';

.employee-list-wrap{
    .employee{
        border: 2px solid $primary-color;
        padding: 12px;
        border-radius: 12px;
        margin-top: 22px;
        img{
            width: 72px;
            height: 72px;
            object-fit: cover;
            border-radius: 50%;
        }
    }
}