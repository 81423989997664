// variables
@import '../helpers/variables';
.media-album{
    .btn{
        i{
            font-size: 35px;
            line-height: 0;
        }
    }
    .media-album-item{
        img{
            width: 100%;
            height: 200px;
            object-fit: cover;
            border-radius: 5px;
        }
    }
    .modal {
        .modal-content{
            height: 100vh;
            overflow-y: scroll;
            .image-wrapper{
                position: relative;
                width: 100%;
                height: 150px;
                margin-top: 10px;
                .form-check-input{
                    position: absolute;
                    margin-top: 0;
                    top: 10px;
                    right: 10px;
                }
                .form-check-input:checked ~ img{
                    border-color: $primary-color;
                }
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 5px;
                    border: 2px solid transparent;
                    cursor: pointer;
                }
            }
        }
    }
}