// variables
@import '../helpers/variables';

// main aside style
.page-aside{
    width: 250px;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $white-color;
    position: fixed;
    left: 0;
    transition: none;
    z-index: 99;
    border-right: 1px solid $border-color;
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &:hover::-webkit-scrollbar-thumb {
        background-color: $gray-color;
        border-radius: 20px;
    }
    // border-right: 1px solid $border-color;
    .aside-brand{
        padding: 20px 15px;
        text-transform: uppercase;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            width: 40%;
            height: 4px;
            border-radius: 10px;
            bottom: 10px;
            background-color: $primary-color;
        }
    }
    ul{
        padding: 0;
        list-style-type: none;
        border: none;
    }

    .nav-link, .single-nav-link{
        padding: 10px 20px;
        color: $black-color;
        display: block;
        transition: none;
        &:hover{
            text-decoration: none;
            color: $primary-color;
            letter-spacing: 1px;
        }
        &.active{
            font-weight: bold;
        }
    }
    .nav-link{
        padding: 5px 0 5px 50px;
        position: relative;
        &::before{
            position: absolute;
            content: '';
            background-color: $secondary-color;
            width: 4px ;
            height: 4px;
            left: 28px;
            top: 13px;
            border-radius: 10px;
        }
    }
    a{
        i{
            font-size: 20px;
            width: 20px;
            margin-right: 10px;
            line-height: 0;
        }
    }
    @media (max-width: 992px) {
        left: -250px;
    }
    &.hide{
        left: -250px ;
        @media (max-width: 992px) {
            left: 0;
        }
    }
}
.page-aside-layer{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: $black-color;
    opacity: .3;
    z-index: 9;
    transition: none;
    display: none;
    &.show{
        @media (max-width: 992px) {
            display: block;
        }
    }
}
