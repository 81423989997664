// variables
@import '../helpers/variables';

.purchase-new-entry{
    .card{
        .card-header{
            background-color: $background-color;
        }
    }
    .bank-inactive, .cash-inactive{
        display: none;
    }
    .input-active{
        display: block;
    }
    .warehouse-wrapper{
        height: 130px;
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: 5px;
        }
    
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
    
        &:hover::-webkit-scrollbar-thumb {
            background-color: $gray-color;
            border-radius: 20px;
        }
        .input-group-text{
            width: 50px;
        }
    }
}
