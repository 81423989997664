.service-wrapper {
  margin-top: 25px;
  .service {
    background-color: $white-color;
    // box-shadow: 0 0 10px rgba($color: $primary-color, $alpha: 0.2);
    border: 2px solid $primary-color;
    text-align: center;
    display: block;
    border-radius: 5px;
    transition: ease 0.2s;
    text-transform: uppercase;
    padding: 20px 20px;
    h5 {
      transition: ease 0.2s;
      color: $gray-color;
    }
    &:hover {
      background-color: $primary-color;
      text-decoration: none !important;
      h5, i, p{
        color: $white-color;
      }
    }
    i {
      font-size: 32px;
      color: $primary-color;
    }
    p {
      font-size: 10px;
      color: $gray-color;
    }
  }
  &.dropdown {
    margin-top: 10px;
    .service {
      box-shadow: none;
      display: flex;
      align-items: center;
      padding: 0 10px;
      border: none;
      i {
        font-size: 22px;
        margin-right: 10px;
      }
      p {
        font-size: 14px;
        transition: ease 0.2s;
      }
      &:hover {
        box-shadow: none;
        background-color: $primary-color;
        text-decoration: none !important;
        i{
          color: $white-color;
        }
        p {
          color: $white-color;
          letter-spacing: 1px;
        }
      }
    }
  }
}
