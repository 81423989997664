@import '../helpers/mixins';

.comming-soon{
    max-width: 800px;
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    .logo{
        max-width: 250px;
        width: 100%;
    }
    .social{
        ul{
            li{
                list-style-type: none;
                font-size: 32px;
                margin-right: 20px;
                transition: all linear .3s;
                &:hover{
                    transform: scale(1.2);
                }
                i{
                    font-size: 26px;
                }
            }
        }
    }
    .countdown{
        ul{
            li{
                list-style-type: none;
                border: 1px solid gray;
                padding: 10px 20px;
                text-align: center;
                border-radius: 5px;
                padding: 0;
                width: 100px;
                margin: 20px ;
                &:first-child{
                    margin-left: 0;
                }
            }
        }
    }
}