// File import
@import './modules/global';
@import './modules/form';
@import './modules/table';


// variables
@import './helpers/variables';
@import './helpers/mixins';


// main style
.setting-pages-aside{
    position: fixed;
    left: 0;
    width: 250px;
    height: 100vh;
    transition: all linear .1s;
    background-color: $white-color;
    z-index: 10;
    h5{
        padding: 11px 0 11px 17px;
        font-size: 18px;
    }
    @media (max-width: 768px) {
        left: -250px;
    } 
    .list-group{
        border-radius: 0;
        margin-top: 20px;
        .list-group-item{
            border: none;
            padding: 7px 1rem;
            transition: all linear .3s;
            &:hover{
                background-color: $border-color;
            }
            &.active{
                background-color: $border-color;
                border-left: 3px solid $primary-color;
                color: $black-color;
            }
        }
    }
    &.hide{
        left: -250px;
        @media (max-width: 768px) {
            left: 0;
        } 
    }
}
.setting-pages-mainber{
    margin-left: 250px;
    transition: all linear .1s;
    @media (max-width: 768px) {
        margin-left: 0;
    }
    &.expand{
        margin-left: 0;
    }
    .expand-button{
        i{
            font-size: 22px;
            line-height: 0;
        }
    }
}
.setting-pages-aside-layer{
    display: none;
    @media (max-width: 992px) {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: transparent;
        opacity: .6;
        z-index: 5;
        background-color: $black-color;
        
    }
    &.show{
        display: block;
    }
}