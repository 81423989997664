// colors
$primary-color:#2CA01C;
$secondary-color: #2F4858;
$background-color: #f9f9f9;
$white-color: #ffffff;
$border-color: #D5D8DD;
$gray-color: gray;
$black-color: black;

// font size
$regular-size: 14px;
$small-size: 12px

