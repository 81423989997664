// variable
@import '../helpers/variables';


h4{
    color: $secondary-color;
}
.title{
    color: $secondary-color;
    position: relative;
    display: inline-block;
    letter-spacing: 5px;
    word-spacing: 10px;
    &::after{
        width: 600px;
        height: 3px;
        content: '';
        background-color: $primary-color;
        bottom: 0;
        right: 0;
        position: absolute;
    }
    span{
        color: $primary-color;
    }
}
.credit-area{
    .credit-person{
        text-align: center;
        img{
            width: 100px;
            height: 100px;
            border-radius: 100px;
            margin-bottom: 10px;
        }
        .sub-title{
            width: 130px;
            margin: 0 auto;
        }
    }
}