// variables
@import '../helpers/variables';
.media-upload{
    .upload-button{
        width: 100%;
        height: 200px;
        border: 1px dotted $gray-color;
        position: relative;
        transition: all linear .3s;
        &:hover{
            color: $primary-color;
        }
        .form-control{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
        }
        i{
            font-size: 52px;
        }
    }
    .btn{
        i{
            font-size: 20px;
            line-height: 0;
        }
    }
}